import Url from '../url';

// export const courseAdminDetailsUrls: any[] = [
//   // new Url('', 'course-list'),
//   new Url('', 'admin-course-detail'),
//   new Url('modules/:id', 'admin-course-detail-module-detail'),
//   new Url('modules', 'admin-course-detail-modules'),
//   new Url('admission/:id', 'admin-course-detail-admission-user-detail'),
//   new Url('admission', 'admin-course-detail-admission'),
//   new Url('invitations', 'admin-course-detail-invitation'),
//   new Url('dev', 'admin-course-detail-dev'),
// ];

// export const courseAdminUrls: Url[] = [
//   // new Url('', 'course-list'),
//   new Url(':slug', 'admin-course-detail', courseAdminDetailsUrls),
// ];

export const makePrivateSurveysUrls: () => Url[] = () => [
  new Url('', 'private-company-survey-list', []),
  new Url('edit', 'private-company-survey-edit', []),
];

export const makePrivateCompanyUrls: () => Url[] = () => [
  new Url('', 'private-company-detail', []),
  new Url('participants', 'private-company-participants', []),
  new Url('edit', 'private-company-edit', []),
  new Url('billing', 'private-company-billing', []),
  new Url('permissions', 'private-company-permissions', []),
  new Url('surveys', 'private-company-surveys', makePrivateSurveysUrls()),
];
export const makePrivateCompaniesUrls: () => Url[] = () => [
  new Url('', 'private-companies-list', []),
  new Url(':id', 'private-companies-detail', makePrivateCompanyUrls()),
];

export const makeProfileUrls: () => Url[] = () => [
  // new Url('companies', 'private-companies', makePrivateCompaniesUrls()),
  new Url('dashboard', 'profile-dashboard', []),
  new Url('certificates', 'profile-dashboard-certificates', []),
  new Url('participation/:id', 'profile-dashboard-participation-detail', []),
  new Url('participations', 'profile-dashboard-participations', []),
  new Url('preferences', 'profile-preferences', [], { it: 'preferenze' }),
  new Url('settings', 'profile-settings', []),
  // new Url('report', 'profile-report', []),
  // new Url('stepper', 'profile-stepper', []),
  new Url('invitations', 'profile-invitations', []),
];

export const makeAccountUrls: () => Url[] = () => [
  new Url('courses', 'private-courses-list', []),
  new Url('companies', 'private-companies', makePrivateCompaniesUrls()),
];

export const authUrls: any[] = [
  new Url('logout', 'auth-logout', [], { it: 'esci' }),
  new Url('login', 'auth-login', [], { it: 'entra' }),
  new Url('email-verified', 'email-verified', [], { it: 'email-verificata' }),
  new Url('password-reset-success', 'password-reset-success', [], {
    it: 'password-reimpostata',
  }),
  new Url('confirm-email/:key', 'confirm-email', [], {
    it: 'conferma-email/:key',
  }),
  new Url('reset-password/:uid/:token', 'reset-password-token', [], {
    it: 'reimposta-password/:uid/:token',
  }),
  new Url('user_deactivation/:code', 'profile-deactivation-code', []),
];

export const makeCourseDashboardManagementUrls: () => Url[] = () => [
  new Url('generic', 'dashboard-course-management-generic'),
  new Url('authors', 'dashboard-course-management-authors'),
  new Url('certificates', 'dashboard-course-management-certificates'),
  new Url('theme', 'dashboard-course-management-theme'),
  new Url('notifications', 'dashboard-course-management-notifications'),
  new Url('jsx', 'dashboard-course-management-jsx-edit'),
];

export const makeCourseDashboardAdmissionDetailUrls: () => Url[] = () => [
  new Url('', 'dashboard-course-admission-detail-assessments'),
  new Url('presence', 'dashboard-course-admission-detail-presence'),
];
export const makeCourseDashboardAdmissionUrls: () => Url[] = () => [
  // new Url('guests', 'dashboard-course-admission-guests'),
  new Url('settings', 'dashboard-course-admission-settings'),
  new Url('guests', 'dashboard-course-admission-guests'),
  new Url(
    'detail/:id',
    'dashboard-course-admission-detail',
    makeCourseDashboardAdmissionDetailUrls(),
  ),
];

export const makeCourseDashboardDetailUrls: () => Url[] = () => [
  new Url('', 'dashboard-course-stats'),
  new Url(
    'management',
    'dashboard-course-management',
    makeCourseDashboardManagementUrls(),
  ),
  new Url(
    'admission',
    'dashboard-course-admission',
    makeCourseDashboardAdmissionUrls(),
  ),
  new Url('permissions', 'dashboard-course-management-permissions'),
  new Url('invitations', 'dashboard-course-management-invitations'),
  new Url('modules/flow', 'dashboard-course-flow'),
  new Url('modules/:id', 'dashboard-course-detail-module-detail'),
];
